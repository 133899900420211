
import React, { useEffect, useState } from "react";
import axios from "axios";
import ClienteInfo from './clienteInfo.js';

const ClientIP = () => {
    const [ip, setIp] = useState("");
    const [error, setError] = useState("");
    const [copied, setCopied] = useState(false);
    
    useEffect(() => {
      const fetchIP = async () => {
        try {
          // Usando el servicio ipify para obtener la IP pública
          const response = await axios.get("https://api.ipify.org?format=json");
          setIp(response.data.ip);
        } catch (err) {
          setError("No se pudo obtener la IP.");
          console.error(err);
        }
      };
  
      fetchIP();
    }, []);
  
    if (error) {
      return <div className="alert alert-danger">{error}</div>;
    }
  
    if (!ip) {
      return <div className="spinner-border text-primary" role="status"></div>;
    }

    const handleCopy = () => {
      navigator.clipboard.writeText(ip).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // El mensaje "Copiado" desaparece después de 2 segundos
      });
    };

    return (<><div style={{ textAlign: "center", paddingTop: "20px" }}>
        <h3 style={{color:'white', paddingBottom: '30px'}}>Tu Dirección IP</h3>
        {ip ? (
          <div  style={{ paddingBottom: '20px' }}>
          <p style={{ fontSize: "2.2rem", color: "yellow", fontWeight: 'bold' }}>{ip}</p>
          <button
            onClick={handleCopy}
            style={{
              padding: "10px 20px",
              fontSize: "1rem",
              cursor: "pointer",
              backgroundColor: `${copied ? "#4CAF50" : "#007bff"}`,
              color: "white",
              border: "none",
              borderRadius: "5px",
            }}
          >
            {copied ? "¡Copiado!" : "Copiar IP"}
          </button>
          </div>
        ) : error ? (
          <p style={{ fontSize: "1.5rem", color: "red" }}>{error}</p>
        ) : (
          <p style={{ fontSize: "1.5rem" }}>Cargando...</p>
        )}
      </div><ClienteInfo miip={`${ip}`}/></>
    );
  };
  
  export default ClientIP;