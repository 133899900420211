import Video from '../components/video.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import Miip from '../components/ip.js';

function App() {
  return (
<div style={{marginTop:'60px'}}>
<Video nombre="../assets/video/contacto.mp4" caption="contacto" replay={true} string={ <div ></div>}/>
<div className='content' style={{position: 'relative'}}><Miip /></div>
</div>
 );
}

export default App;
