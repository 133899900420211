import React, { useEffect, useState } from "react";
import axios from "axios";

const ClientInfo = ({ miip }) => {
  const [clientInfo, setClientInfo] = useState(null);
  const [timeZone, setTimeZone] = useState(null);
  const [error, setError] = useState("");
  const clienteInfoKey = process.env.REACT_APP_CLIENTE_INFO;
  const [whois, setWhois] = useState(false);


  const handleWhoIs = () => {
    try {
      setWhois(true);
      setTimeout(() => setWhois(false), 2000); // El mensaje "Copiado" desaparece después de 2 segundos
      fetchClientInfo();
      
      if (!clientInfo) {
        return <div className="spinner-border text-primary" role="status"></div>;
      }
    }catch (err) {
      setError(`Error al obtener la información WhoIs: ${err.message}`);
      console.error("Detalles del error WhoIs:", err.response || err);
    }
  };

  const fetchClientInfo = async () => {
    try {
      const response = await axios.get(`https://ipinfo.io/${miip}/?token=${clienteInfoKey}`);
      console.log("Respuesta de ipinfo.io:", response.data);
      setClientInfo(response.data);
      
    } catch (err) {
      setError(`Error al obtener la información: ${err.message}`);
      console.error("Detalles del error:", err.response || err);
    }
  };





  useEffect(() => {

    if (!miip) {
      setError("La IP no fue detectada.");
      console.error("La IP no fue detectada.");
      return;
    }



  }, [miip]);


    useEffect(() => {
      if (clientInfo != null) {

      if (!clientInfo.timezone) {
        setError("La Zona horaria no fue detectada en la información proporcionada por el servicio.");
        console.error("Zona horaria no disponible en los datos.");
        return;
      } else {

        const fetchTimeZoneInfo = async () => {
          try {
            const response = await axios.get(`https://worldtimeapi.org/api/timezone/${clientInfo.timezone}`);
            setTimeZone(response.data?.datetime);
            console.log("Respuesta de worldtimeapi.org:", response.data);
          } catch (err) {
            setError(`Error al obtener la información: ${err.message}`);
            console.error("Detalles del error:", err.response || err);
          }
        };
        fetchTimeZoneInfo();

      }
    }
    }, [clientInfo]);


  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

/*   if (!clientInfo) {
    return <div className="spinner-border text-primary" role="status"></div>;
  }
 */
  return (
    <>{!whois && <button
    onClick={handleWhoIs}
    style={{
      padding: "10px 20px",
      fontSize: "1rem",
      cursor: "pointer",
      backgroundColor: `${whois ? "#4CAF50" : "#007bff"}`,
      color: "white",
      border: "none",
      borderRadius: "5px",
      marginTop: "20px",
    }}
  >
{whois ? 'Buscando...' : 'Buscar'}
  </button> }
  
  {clientInfo && <div className="container text-center mt-4" style={{ paddingBottom: '20px', maxWidth: '600px', display: whois ? 'visible' : 'hidden' }}>
      <div className="card shadow-sm" style={{ backgroundColor: '#007bff' }}>
        <div className="card-body">
          <div className="row text-white">
            <div className="col-6 text-start fw-bold">Compañía:</div>
            <div className="col-6 text-start">{clientInfo.asn?.name || clientInfo?.org}</div>
            <div className="col-6 text-start fw-bold">Hostname:</div>
            <div className="col-6 text-start">{clientInfo?.hostname || "No disponible"}</div>
            <div className="col-6 text-start fw-bold">Tipo:</div>
            <div className="col-6 text-start">{clientInfo.asn?.type || "No disponible"}</div>
            <div className="col-6 text-start fw-bold">ASN:</div>
            <div className="col-6 text-start">{clientInfo.asn?.asn || "No disponible"}</div>
            <div className="col-6 text-start fw-bold">Ruta:</div>
            <div className="col-6 text-start">{clientInfo.asn?.route || "No disponible"}</div>
            <div className="col-6 text-start fw-bold">Dominio:</div>
            <div className="col-6 text-start">{clientInfo.asn?.domain || "No disponible"}</div>
            <div className="col-6 text-start fw-bold">Ciudad:</div>
            <div className="col-6 text-start">{clientInfo?.city || "No disponible"}</div>
            <div className="col-6 text-start fw-bold">Región:</div>
            <div className="col-6 text-start">{clientInfo?.region || "No disponible"}</div>
            <div className="col-6 text-start fw-bold">País:</div>
            <div className="col-6 text-start">{clientInfo?.country || "No disponible"}</div>
            <div className="col-6 text-start fw-bold">Zona Horaria:</div>
            <div className="col-6 text-start">{clientInfo?.timezone || "No disponible"}</div>
            <div className="col-6 text-start fw-bold">Hora:</div>
            <div className="col-6 text-start">{timeZone?.substring(11, 19) || "No disponible"}</div>
            <div className="col-6 text-start fw-bold">Código Postal:</div>
            <div className="col-6 text-start">{clientInfo?.postal || "No disponible"}</div>
            <div className="col-6 text-start fw-bold">Coordenadas:</div>
            <div className="col-6 text-start">{clientInfo?.loc || "No disponible"}</div>
          </div>
        </div>
      </div>
    </div>}</>
  );
};

export default ClientInfo;