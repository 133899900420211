import Video from '../components/video.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import MiQR from '../components/miQR.js';

function Qr() {
  return (
<div style={{paddingTop:'150px'}}>
<Video nombre="../assets/video/contacto.mp4" caption="contacto" replay={true} string={ <div ></div>}/>
<div className='content' style={{position: 'relative'}}><MiQR /></div>
</div>
 );
}

export default Qr;
