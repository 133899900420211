import React, { useState, useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

function QRCodeGenerator() {
  const [link, setLink] = useState('');
  const canvasRef = useRef(null);

  const handleDownload = () => {
    const canvas = canvasRef.current.querySelector('canvas');
    const url = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = url;
    link.download = 'codigo-qr.png';
    link.click();
  };

  return (
    <div style={{marginLeft: '28%', marginRight: '28%', paddingTop: '20px'}}>
      <h1 style={{backgroundColor: '#fff', color: '#000'}}>Generador de Código QR</h1>
      <input
        type="text"
        placeholder="Ingresa un enlace"
        value={link}
        onChange={(e) => setLink(e.target.value)}
        style={{
          padding: '10px',
          width: '80%',
          maxWidth: '400px',
          marginBottom: '20px',
          fontSize: '14px',
          color: '#000',
        }}
      />
      {link && (
        <div>
            <div ref={canvasRef}>
                <QRCodeCanvas value={link} size={256} level="H" includeMargin={true} />
            </div>
            <div style={{marginLeft: '20%', marginRight: '20%'}}>
                <p style={{backgroundColor: '#fff', color: '#000'}}>Escanea este código QR para abrir el enlace.</p>
                <p><button
            onClick={handleDownload}
            style={{
              padding: '10px 20px',
              marginTop: '20px',
              backgroundColor: '#007bff',
              color: '#ffffff',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '16px',
            }}
          >
            Descargar Código QR
          </button></p>
            </div>
        </div>
      )}
    </div>
  );
}

export default QRCodeGenerator;
