import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Menu from './components/Menu.js';
import Nosotros from './pages/Nosotros.js';
import Portada from './pages/Portada.js';
import Contacto from './pages/Contacto.js';
import Proyectos from './pages/Proyectos.js';
import Sinergy from './pages/Sinergy.js';
import Productos from './pages/Productos.js';
import Wsp from './components/wsp.js';
import Miip from './pages/Miip.js';
import MiQR from './pages/Qr.js';
import Indicadores from './pages/Indicadores.js';

import './assets/mp3/applite.mp3'
function App() {
  return (
   <div>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Portada />} />
        <Route path="/nosotros/" element={<Nosotros />} />
        <Route path="/mi-ip/" element={<Miip />} />
        <Route path="/generador-qr-codeqr-gratis/" element={<MiQR />} />
        <Route path="/indicadores-economicos/" element={<Indicadores />} />
        <Route path="/productos/" element={<Productos />} />
        <Route path="/proyectos/" element={<Proyectos />} />
        <Route path="/contacto/" element={<Contacto />} />
        {/* <Route path="/Sinergy/" element={<Sinergy />} /> */}
        <Route path="*" element={<Navigate to="/"/>} />
      </Routes>
      <div style={{position:'fixed', top:'0px',left:'0px',display:'block',zIndex:'1'}}><Menu /></div>
      <Wsp />
    </BrowserRouter></div>
  );
}

export default App;