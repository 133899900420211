import React, { useEffect, useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const EconomicIndicators = () => {
  const [indicators, setIndicators] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchIndicators = async () => {
      try {
        const response = await axios.get("https://mindicador.cl/api");
        setIndicators(response.data);
      } catch (err) {
        setError("No se pudieron obtener los indicadores económicos.");
        console.error(err);
      }
    };

    fetchIndicators();
  }, []);

  return (
    <div className="container mt-5" style={{ textAlign: "center", paddingTop: "20px" }}>
      <h1 className="text-center mb-4" style={{ color: '#fff' }}>Indicadores Económicos de Chile</h1>
      {error ? (
        <div className="alert alert-danger text-center" role="alert">
          {error}
        </div>
      ) : indicators ? (
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
          <IndicatorCard
            icon="bi bi-currency-exchange"
            title="UF"
            value={indicators.uf.valor}
            unit="CLP"
            fecha={indicators.uf.fecha}
          />
          <IndicatorCard
            icon="bi bi-cash"
            title="Dólar"
            value={indicators.dolar.valor}
            unit="CLP"
            fecha={indicators.dolar.fecha}
          />
          <IndicatorCard
            icon="bi bi-currency-euro"
            title="Euro"
            value={indicators.euro.valor}
            unit="CLP"
            fecha={indicators.euro.fecha}
          />
          <IndicatorCard
            icon="bi bi-coin"
            title="Bitcoin"
            value={indicators.bitcoin.valor}
            unit="CLP"
            fecha={indicators.bitcoin.fecha}
          />
          <IndicatorCard
            icon="bi bi-bar-chart-line"
            title="UTM"
            value={indicators.utm.valor}
            unit="CLP"
            fecha={indicators.utm.fecha}
          />
          <IndicatorCard
            icon="bi bi bi-graph-up"
            title="IPC"
            value={indicators.ipc.valor + ' %'}
            unit="CLP"
            fecha={indicators.ipc.fecha}
          />
          <IndicatorCard
            icon="bi bi bi-graph-up"
            title="Tasa de desempleo"
            value={indicators.tasa_desempleo.valor + ' %'}
            unit="CLP"
            fecha={indicators.tasa_desempleo.fecha}
          />
        </div>
      ) : (
        <div className="text-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Cargando...</span>
          </div>
        </div>
      )}
    </div>
  );
};

const IndicatorCard = ({ icon, title, value, unit, fecha }) => {

  const today = new Date().toISOString().substring(0, 10); // Obtener la fecha actual en formato ISO
  const displayFecha = fecha?.substring(0, 10) === today ? "Hoy" : fecha?.substring(0, 10);

  {/* <div class="col-md-3 col-sm-6">
                <div class="widget widget-indicator">
                    <div class="indicator-icon"><i class="fa fa-usd"></i></div>
                    <div class="indicator-info">
                        <h3>UF</h3>
                        <p>38.374,62</p>
                    </div>
                    <div class="indicator-link">
                        <a href="https://mindicador.cl/api/uf">Ver valores actuales <i class="fa fa-search fa-lg"></i></a>
                    </div>
                </div>
            </div>
 */}
  return (
    <div className="col" >
      <div className="card shadow-sm border-0" style={{ backgroundColor: '#007bff' }}>
        <div className="card-body text-center" style={{ color: '#fff' }}>
          <i className={`${icon} fs-1 mb-3`} style={{ color: '#e4f367' }} ></i>
          <h5 className="card-title">{title}</h5>
          <p className="card-text fs-4 fw-bold">
            {value?.toLocaleString("es-CL")}
            <span className="ms-2 fs-6" style={{ color: '#fff' }}>{unit}</span>
          </p>
          <p>{displayFecha}</p>
        </div>
        <div style={{backgroundColor:'#fff', color: '#000'}}>
          <a href="/uf">Ver valores actuales <i class="fa fa-search fa-lg"></i></a>
        </div>
      </div>
    </div>
  );
};

export default EconomicIndicators;